export const environment = {
  production: false,
  api: {
    url: 'https://apidev.domusign.com',
  },
  document: {
    url: 'https://apidev.domusign.com/document',
  },
  home: {
    url: 'https://dev.domusign.com',
  },
  pricing: [],
  web: false,
  pdf: true,
  langs: ["en", "fr"],
  adobe_pdf_viewer: {
    client_id: 'a56ae806b49e48a58d1b5db68915ed9c',//pdfdev.domusign.com
    //client_id: '2e44f95fde8a47e08ef05831f0d7a692',//localhost
  },
};
 